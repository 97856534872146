<script src="parcels-list.component.ts"></script>
<script src="../parcels/parcels.component.ts"></script>
<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'parcels' | translate}}
            <button class="uk-button uk-button-primary padding-horizontal-small button-opt"
                    routerLink="/parcel/new"
                    *ngIf="userModel.role != UserRole.warehouse_worker"
                    [attr.uk-tooltip]="'Dodaj przesyłkę'"
            >
              <span uk-icon="plus"></span>
            </button>
          <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
             routerLink="/parcel/return"
             *ngIf="userModel.role != UserRole.warehouse_worker"
             [attr.uk-tooltip]="'Utwórz zwrot'"
          >
            <span uk-icon="history"></span>
          </a>
            <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
               *ngIf="userModel.role === UserRole.admin"
               (click)="downloadFile(true)"
               [attr.uk-tooltip]="'Przelicz i pobierz zestawienie'"
            >
              <span uk-icon="file-edit"></span>
            </a>
          <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
             *ngIf="userModel.role === UserRole.admin || userModel.role === UserRole.client"
             (click)="downloadFile(false)"
             [attr.uk-tooltip]="'Pobierz zestawienie'"
          >
            <span uk-icon="file-text"></span>
          </a>

            <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
               *ngIf="userModel?.role === UserRole.admin && 'parcels' === criteria.type"
               [attr.uk-tooltip]="'Zmień statusy'"
               (click)="openGroupedStatus()"
            >
              <span uk-icon="commenting"></span>
            </a>
            <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
               *ngIf="userModel?.role === UserRole.admin && 'parcels' === criteria.type"
               (click)="selectAll()"
               [attr.uk-tooltip]="lastChecked ? 'Odznacz przesyłki' : 'Zaznacz przesyłki'"
            >
              <span uk-icon="check"></span>
            </a>
            <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
               [attr.uk-tooltip]="'Resetuj filtry'"
               (click)="refreshFilters()"
            >
              <span uk-icon="refresh"></span>
            </a>
        </p>
    </div>
    <div class="uk-width-1-1 inner-shadow">
      <div
        style="margin-left: -30px!important; margin-bottom: 100px !important; margin-right: -30px!important;"
        class="uk-card uk-card-default uk-card-body uk-margin-small-right uk-margin-small-top uk-padding-small">
        <div class="mobile-overflow">
          <div class="uk-grid">
            <div class="uk-width-1-5">
              <div class="uk-grid">
                <div class="uk-width-1-2">
                  <label>Rodzaj listy</label>
                  <select class="uk-select" [(ngModel)]="criteria.type" disabled>
                    <option value="parcels">Przesyłki</option>
                    <option value="shipments">Listy przewozowe</option>
                  </select>
                </div>
                <div class="uk-width-1-2">
                  <label title="Rodzaj przesyłek">R. przesyłek</label>
                  <select class="uk-select" [(ngModel)]="criteria.deleted">
                    <option value="0">Aktywne</option>
                    <option value="1">Usunięte</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-width-1-5">
              <div class="uk-grid">
                <div class="uk-width-1-2@m">
                  <label>Paczkomat</label>
                  <select class="uk-select" [(ngModel)]="criteria.locker">
                    <option value="">Nie wybrano</option>
                    <option value="1">Tak</option>
                    <option value="0">Nie</option>
                  </select>
                </div>
                <div class="uk-width-1-2@m">
                  <label>Pobranie</label>
                  <select class="uk-select" [(ngModel)]="criteria.cod">
                    <option value="">Nie wybrano</option>
                    <option value="1">Tak</option>
                    <option value="0">Nie</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-width-1-5">
              <div class="uk-grid">
                <div class="uk-width-1-2@m">
                  <label>{{'from' | translate}}</label>
                  <input type="date" name="search-from" [(ngModel)]="criteria.start" class="uk-input">
                </div>
                <div class="uk-width-1-2@m">
                  <label>{{'to' | translate}}</label>
                  <input type="date" name="search-to" [(ngModel)]="criteria.end" class="uk-input">
                </div>
              </div>
            </div>
            <div class="uk-width-1-5">
              <label>Status</label>
              <select class="uk-select" [(ngModel)]="criteria.status_group_id">
                <option value="">Nie wybrano</option>
                <option *ngFor="let statusGroup of statusGroups" value="{{statusGroup.id}}">{{statusGroup.name}}</option>
              </select>
            </div>
            <div class="uk-width-1-5" style="padding-right: 30px !important;">
              <br>
              <button class="uk-button uk-button-primary uk-width-1-1" (click)="runFilters()">
                Filtruj
              </button>
            </div>
            <div class="uk-width-1-6">
              <label *ngIf="UserRole.client !== userModel?.role">Numer wewnętrzny</label>
              <label *ngIf="UserRole.client === userModel?.role">Numer przesyłki</label>
              <input class="uk-input" [(ngModel)]="criteria.internal_number">
            </div>
            <div class="uk-width-1-6">
              <label>Numer wyjściowy</label>
              <input class="uk-input" [(ngModel)]="criteria.out_number">
            </div>
            <div class="uk-width-1-2">
              <div class="uk-grid">
                <div class="uk-width-1-3" *ngIf="userModel?.role < UserRole.client">
                  <label>Numer zwrotny</label>
                  <input class="uk-input" [(ngModel)]="criteria.return_number">
                </div>
                <div class="uk-width-1-3" *ngIf="userModel?.role < UserRole.client">
                  <label>Klient</label>
                  <input autocomplete="off" [class.uk-form-danger]="clientInput.touched && id_client.value == ''" (keyup)="searchClient(clientInput)" type="text" #clientInput="ngModel" name="client" [(ngModel)]="criteria.client_name" class="uk-input" required #clientField>
                  <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                    <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="clients.length > 0" [style.width]="clientField.clientWidth + 'px'">
                      <li *ngFor="let client of clients" (click)="setClient(client);" style="padding-left: 5px!important;">{{client.name}}</li>
                    </ul>
                  </div>
                  <input type="hidden" #id_client="ngModel" autocomplete="none" [(ngModel)]="criteria.client_id"  class="uk-input">
                </div>
                <div class="uk-width-1-3" *ngIf="userModel?.role < UserRole.client">
                  <label>Kraj</label>
                  <select class="uk-select" [(ngModel)]="criteria.country">
                    <option value="">Nie wybrano</option>
                    <option *ngFor="let country of countries" value="{{country.value}}">{{country.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="uk-width-1-6" style="padding-right: 30px !important;" *ngIf="userModel?.role < UserRole.client">
              <label>Procesowalna</label>
              <select class="uk-select" [(ngModel)]="criteria.processable">
                <option value=""></option>
                <option value="1">Tak</option>
                <option value="0">Nie</option>
              </select>
            </div>

            <!---
            <div *ngIf="userModel.role == 0" class="uk-width-1-1@m uk-margin-top">
              <ul class="mobile-overflow uk-accordion" uk-accordion>
                <li class="uk-close">
                  <p class="uk-accordion-title uk-heading-divider" href="#" [title]="'Pozostałe informacje służące diagnostyce'">Pozostałe filtry...</p>
                  <div class="uk-accordion-content">
                    <div class="uk-grid">

                    </div>
                  </div>
                </li>
              </ul>
            </div>
-->
          </div>
          <br>
          <table
            class="uk-table uk-table-hover uk-table-divider" style="width:100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="parcelListTable">
            <thead *ngIf="criteria.type == 'parcels'">
              <tr>
                <th style="width: 10%">{{'internal_number' | translate}}</th>
                <th>{{'status' | translate}}</th>
                <th>{{'recipient_name' | translate}}</th>
                <th *ngIf="userModel?.role < UserRole.client">{{'client' | translate}}</th>
                <th>{{'destination_city' | translate}}</th>
                <th>{{'out_number' | translate}}</th>
                <th *ngIf="userModel?.role < UserRole.client">{{'return_number' | translate}}</th>
                <th>{{'declared_content' | translate}}</th>
                <th>{{'cash_on_delivery_value' | translate}}</th>
                <th>{{'created_at' | translate}}</th>
                <th *ngIf="userModel?.role < UserRole.client"></th>
              </tr>
            </thead>
            <tfoot  id="footer">
            </tfoot>
            <tbody *ngIf="items?.length > 0 && criteria.type == 'parcels'">
            <tr *ngFor="let item of items"
                (mouseout)="setGoToLocker(false)"
                (click)="goTo( '/parcel/' + item.internal_number )"
                [title]="userModel?.role < UserRole.client ? item.error_message : ''"
            >
              <td (mouseup)="setGoToLocker(true)">
                <img src="{{flagUrl(item)}}" title="{{country(item)}}">
                <kbd>{{item.internal_number}}</kbd>
              </td>
              <td>{{item.status_name}}</td>
              <td>{{item.recipient_name}}</td>
              <td *ngIf="userModel?.role < UserRole.client"> {{item.client_name}} </td>
              <td>{{item.return_parcel ? item.source_city : item.destination_city}}</td>
              <td (mouseup)="setGoToLocker(true)">
                {{ item.buffer_number === null ? (item.out_number === '-' ? item.in_number : item.out_number) : item.buffer_number }}
              </td>
              <td *ngIf="userModel?.role < UserRole.client"  (mouseup)="setGoToLocker(true)">
                {{item.return_number}}
              </td>
              <td>
                {{item.declared_content}}
              </td>
              <td>
                {{item.cash_on_delivery ? item.cash_on_delivery_value + ' ' + item.currency : '-'}}
              </td>
              <td>
                {{item.created_at}}
              </td>
              <td *ngIf="userModel.role < UserRole.client" (mouseup)="setGoToLocker(true)">
                <input *ngIf="userModel.role < UserRole.client" class="uk-checkbox" type="checkbox" [(ngModel)]="item.selected">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
<div id="changeGrouppedStatus" uk-modal class="changeGrouppedStatus">
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'change_status' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
      <div class="uk-width-1-1@m">
        <label>{{'choose_status' | translate}}</label>
        <select name="id_status_type" required class="uk-input" [(ngModel)]="statusModel.id_status_type">
          <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">{{statusType.displayed_name}}
          </option>
        </select>
      </div>

      <div class="uk-width-1-1@m">
        <label>{{'when' | translate}}</label>
        <input type="datetime-local" autocomplete="none" name="when"  [(ngModel)]="statusModel.when" required data-uk-timepicker class="uk-input">
      </div>

      <div class="uk-width-1-1@m">
        <label>{{'where' | translate}}</label>
        <input type="text" autocomplete="none" name="where" [(ngModel)]="statusModel.where" required class="uk-input">
      </div>

      <div class="uk-margin">
        <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" (click)="setGroupStatuses()">{{'change_status' | translate}}</button>
      </div>
    </div>
  </div>
</div>

