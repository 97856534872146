<div id="export-list-modal" class="export-list-modal" uk-modal>
  <div class="uk-modal-dialog" style="width: 80% !important;">

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">Moje raporty</h2>

      <button class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-small-right"
              uk-tooltip="Odśwież listę raportów" title="" aria-expanded="false"
              (click)="refreshDataOnly()"
              style="position: absolute; top: 0px; right: 60px;" uk-icon="refresh">
      </button>

      <button class="uk-modal-close-default" type="button" uk-close></button>
    </div>
    <div class="uk-modal-body">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="exportedFilesTable"
             class="uk-table uk-table-hover uk-table-divider uk-width-1-1">
        <thead>
        <tr>
          <th>
            Data zlecenia
          </th>
          <th *ngIf="userModel.role === 0">
            {{ 'client' | translate }}
          </th>
          <th>
            {{ 'name' | translate }}
          </th>
          <th class="uk-text-center">
            {{ 'status' | translate }}
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let ex of exports" title="Kliknij aby pobrać">
          <tr
              (click)="handleRowClick(ex)"
              [class.uk-open]="isOpen === ex"
              [ngStyle]="{'background-color': isOpen === ex ? 'var(--primary-re)' : 'white',
              'color': isOpen === ex ? 'white' : '#666666'}"
              style="cursor: pointer" title="Kliknij aby pobrać">

            <td style="width: 10%">
              {{ ex.created_at | date }}
            </td>
            <td *ngIf="userModel.role === 0" class="uk-width-1-4">
              {{ ex.client_name === null ? 'Wszyscy' : ex.client_name }}
            </td>
            <td>
              {{ ex.name }}
            </td>
            <td class="uk-text-center uk-width-1-6" [title]="null === ex.error ? '' : ex.error"
                [class.text-warning]="'' !== ex.error">

              <span *ngIf="ex.status === 'downloaded' && !ex.error" style="color:green;">{{ ex.status | translate }}</span>
              <span *ngIf="ex.status === 'generated'" style="color:green;">{{ ex.status | translate }}</span>
              <span *ngIf="ex.status === 'downloaded' && ex.error" style="color:orange;">{{ ex.status | translate }} </span>
              <span *ngIf="ex.status === 'validated'" style="color:green;">{{ ex.status | translate }}</span>
              <span *ngIf="ex.status === 'accounting'" style="color:gray;">{{ ex.status | translate }}</span>
              <span *ngIf="ex.status === 'order_accepted'" style="color:gray;">{{ ex.status | translate }}</span>
              <span *ngIf="ex.status === 'validation_error'" style="color:orange;">{{ ex.status | translate }} </span>
              <span *ngIf="ex.status === 'verified' || ex.status === 'file_deleted'">{{ex.status | translate}}</span>
              <span *ngIf="'accounting' === ex.status && ex.total_count > 0">
                {{ ((ex.performed_count / ex.total_count) * 100).toFixed(2) }} %
              </span>
            </td>
          </tr>
          <tr *ngIf="isOpen === ex && ex.errors_count > 0 && userModel.role < 3" class="fictionHover">
            <td colspan="4">
              <div>
                <div style="background-color: #f3f1f1;">

                  <button class="uk-button uk-button-primary padding-horizontal-small"
                          (click)="download(ex)"
                          uk-tooltip="Pobierz raport" title="" aria-expanded="false" uk-icon="file-text">Pobierz raport
                  </button>

                  <table class="uk-width-1-1" style="background-color: #f3f1f1;" id="validation-errors-table">
                    <thead>
                      <tr>
                        <th>
                          LP
                        </th>
                        <th>
                          Błąd walidacji
                        </th>
                        <th style="font-size: 10px">
                          Zweryfikowano
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let error of ex.validation_errors; index as i" class="fictionHover">
                      <td style="width: 3%"> {{ i + 1 }} </td>

                      <td *ngIf="1 >= error.parcelInternalNumbers.length"
                          (click)="openParcel(error)"
                          title="Kliknij aby otworzyć!"
                         class="link">
                        {{ exportService.formatErrorMessage(error) | translate}}
                        <span *ngFor="let dimensionNumber of Object.keys(error.additionalData?.dimensions ?? [])">
                            <br>
                            waga: {{error.additionalData.dimensions[dimensionNumber].weight}} kg; wymiary: {{error.additionalData.dimensions[dimensionNumber].width}}x{{error.additionalData.dimensions[dimensionNumber].height}}x{{error.additionalData.dimensions[dimensionNumber].length}}
                        </span>
                      </td>
                      <td *ngIf="error.parcelInternalNumbers.length > 1" class="link">
                        {{ exportService.formatErrorMessage(error) | translate}} <br>
                        Przesyłki składowe wielopaka to:
                        <kbd *ngIf="undefined == error.additionalData.dimensions">
                          <a *ngFor="let number of error.parcelInternalNumbers" href="parcel/{{number}}" target="_blank">{{number}}, </a>
                        </kbd>
                        <span *ngFor="let dimensionNumber of Object.keys(error.additionalData?.dimensions ?? [])">
                           <br>
                          <a href="parcel/{{dimensionNumber}}" target="_blank"><kbd>{{dimensionNumber}}</kbd></a> waga: {{error.additionalData.dimensions[dimensionNumber].weight}} kg; wymiary: {{error.additionalData.dimensions[dimensionNumber].width}}x{{error.additionalData.dimensions[dimensionNumber].height}}x{{error.additionalData.dimensions[dimensionNumber].length}}
                        </span>
                      </td>
                      <td style="align-content: center">
                        <input type="checkbox" [(ngModel)]="error.reviewed" (change)="reviewValidationError(error)">
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
