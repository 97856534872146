import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ExportService {
  private apiurl = '';

  private validationErrors: { [error: string]: string } = {
    missing_amount: 'Przesyłka %s nie została naliczona',
    giant_amount: 'Przesyłka %s posiada niepokojąco wysokie naliczenie, Kwota: {{amount}}, Waga rozliczeniowa: {{accountingWeight}}, liczba przesyłek: {{parcelsCount}}',
    wrong_shipments_in_report_count: 'Liczba przesyłek(exported_count) w pliku jest inna od oczekiwanej (expected_count)!',
    no_operational_price_list_match: 'Nie można naliczyć z powod braku cennika: typ: {{operationalPriceListType}}, rodzaj: {{operationalPriceListSpecialAssociation}}, clientId: {{clientId}}, countryId: {{countryId}}',
    uncompleted_shipment_missing_parcel: 'Przesyłka %s nie znalazła się zestawieniu ze względu na niekompletnie przyjęty wielopak w magazynie zwrotów!'
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
  ) {
    this.apiurl = environment.api + 'app/v1/export';
  }

  countryNames = {
    1: 'Rumunia',
    2: 'Polska',
    3: 'Węgry',
    4: 'Bułgaria'
  };


  makeOrder(form) {
      return this.http.post(this.apiurl, form);
  }

  showByUuid(uuid) {
    return this.http.get(this.apiurl + '/find/' + uuid);
  }

  findValidationErrorsByUuid(uuid) {
    return this.http.get(this.apiurl + '/find/' + uuid + '/validation_errors');
  }

  reviewValidationError(id, reviewed) {
    return this.http.patch(this.apiurl + '/validation_error/', {
      id, reviewed,
    });
  }

  getMyLatestByType(type = null) {
    let url = this.apiurl + '/latest/';
    if (null !== type) {
      url += type;
    }

    return this.http.post(url, []);
  }

  formatErrorMessage(error): string {
    if ('no_operational_price_list_match' === error.type) {
      return this.validationErrors[error.type]
        .replace('{{operationalPriceListType}}', (this.translateService.instant(error.additionalData.operationalPriceListType)))
        .replace('{{operationalPriceListSpecialAssociation}}',
          (this.translateService.instant(error.additionalData.operationalPriceListSpecialAssociation)))
        .replace('{{clientId}}', error.additionalData.clientId)
        .replace('clientId', 'ID Klienta')
        .replace('{{countryId}}', this.findCountryName(error.additionalData.countryId))
        .replace('countryId', 'Kraj');
    }

    if (error.type === 'wrong_shipments_in_report_count') {
      return this.validationErrors[error.type]
        .replace('exported_count', error.additionalData.exported_count)
        .replace('expected_count', error.additionalData.expected_count);
    }

    if (undefined === this.validationErrors[error.type]) {
      return error.type;
    }

    if ('giant_amount' === error.type) {
      let msg = this.validationErrors[error.type]
        .replace('%s', error.internalWaybill)
        .replace('{{amount}}', error.additionalData.amount)
        .replace('{{accountingWeight}}', error.additionalData.accountingWeight)
        .replace('{{parcelsCount}}', error.additionalData.parcelsCount);

      return msg;
    }

    return this.validationErrors[error.type]
      .replace('%s', error.internalWaybill);
  }

  findCountryName(id) {
    return this.countryNames[id];
  }

}
