import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth/auth.service';
import {UserModel} from '../../../models/user.model';
import {ListingService} from '../../../services/listing/calendar-days.service';
import {ToastsService} from '../../../services/toasts.service';
import {StatusGroupsService} from '../../../services/status-groups/status-groups.service';
import {ClientsService} from '../../../services/clients/clients.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {Router} from '@angular/router';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {ParcelService} from '../../../services/parcel/parcel.service';
import {ExportService} from '../../../services/export/export.service';
import {UserRole} from "../../../enums/user-role";
import {StatusTypeService} from "../../../services/status-type/status-type.service";

declare var UIkit: any;

@Component({
  selector: 'app-parcels-list',
  templateUrl: './parcels-list.component.html',
  styleUrls: ['./parcels-list.component.scss']
})
export class ParcelsListComponent implements OnInit {
  criteria = {
      type: 'parcels',
      start: null,
      end: null,
      client_id: null,
      status_group_id: null,
      courier_id: '',
      locker: '',
      cod: '',
      country: null,
      out_number: null,
      internal_number: null,
      return_number: null,
      deleted: 0,
      client_name: null,
      last_status_id: null,
      processable: null,
  };

  countries = [
    {id: 1, name: 'Rumunia', iso_code: 'RO', value: 'Romania'},
    {id: 3, name: 'Węgry', iso_code: 'HU', value: 'Hungary'},
    {id: 4, name: 'Bułgaria', iso_code: 'BG', value: 'Bulgaria'},
  ];
  statusGroups = [];

  LOCAL_STORAGE_KEY = 'v2-parcels-list-criteria-';

  clients = [];
  apiUrl = null;
  dtTranslation = null;
  lastChecked = false;

  items = [];
  dOptions = null;
  lastEvent = null;
  public dtInit = false;
  lastEventProcessing = false;
  statusModel = {
    ids: [],
    id_status_type: null,
    when: null,
    where: 'TYCHY',
  };
  public userModel: UserModel = new UserModel();
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  timeout = 100;
  goToLocker = false;
  exportUuid = null;
  exportDownloadable = false;
  subscriptions = [];
  statusTypes = [];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastsService: ToastsService,
    private statusGroupsService: StatusGroupsService,
    private clientsService: ClientsService,
    private parcelsService: ParcelsService,
    private exportService: ExportService
  ) {
    this.apiUrl = environment.api;
    this.dtTranslation = toastsService.dtTranslation;
  }

  ngOnInit() {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
    this.setCriteria();
    this.statusGroupsService.list().subscribe(
      (response: []) => {
        this.statusGroups = response;
      }
    );
    setTimeout(() => {
      this.showParcels();
    }, 500);
    setTimeout(
      () => {
        this.getStatusTypes();
      }, 500
    );
    this.setTime();
  }

  generateLocalStorageKey() {
    return btoa(this.LOCAL_STORAGE_KEY+this.userModel.id);
  }

  setCriteria() {
    const savedCriteria = localStorage.getItem(this.generateLocalStorageKey());
    if (null === savedCriteria) {
      this.criteria = {
        type: 'parcels',
        start: this.findDate(),
        end: this.findDate(),
        client_id: null,
        status_group_id: null,
        courier_id: null,
        locker: null,
        cod: null,
        country: null,
        out_number: null,
        internal_number: null,
        return_number: null,
        deleted: 0,
        client_name: null,
        last_status_id: null,
        processable: null,
      };
      return;
    }

    decodeURIComponent(
      escape(
        atob(savedCriteria)
      )
    )

    this.criteria = JSON.parse(
      decodeURIComponent(
        escape(
          atob(savedCriteria)
        )
      )
    );
  }

  searchClient(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.clients = response;
      }
    );
  }

  getStatusTypes() {
    if (UserRole.client === this.userModel.role) {
      return;
    }
    this.parcelsService.findStatusTypesForWarehouseSelect().subscribe(
      (response: any) => {
        if (response.length > 0) {
          this.statusModel.id_status_type = response[0].id;
        }
        this.statusTypes = response;
      }
    );
  }

  setTime() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    this.statusModel.when = `${year}-${month}-${day}T${hour}:${minutes}`;
  }

  setClient(client): void {
    this.clients = [];
    this.criteria.client_id = client.id;
    this.criteria.client_name = client.name;
  }

  findDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${year}-${month}-${day}`;
  }

  goTo(url) {
    if (!this.goToLocker) {
      window.location = url;
    }
  }

  showShipments() {

  }

  runFilters() {
    var table: DataTables.Api = $('#parcelListTable').DataTable();
    table.search('').draw();
    table.page(0);

    localStorage.setItem(
      this.generateLocalStorageKey(),
      btoa(
        unescape(
          encodeURIComponent(
            JSON.stringify(
              this.criteria,
            )
          )
        )
      )
    );

    if ('parcels' === this.criteria.type) {
      this.items = [];
      this.showParcels();
    } else {
      this.showShipments();
    }
  }

  refreshFilters() {
    localStorage.removeItem(this.generateLocalStorageKey());
    this.setCriteria();
    this.lastEvent = null;
    this.runFilters();
  }

  showParcels() {
    if (this.lastEvent != null) {
      clearTimeout(this.lastEvent);
    }

    if (this.lastEventProcessing === true) {
      setTimeout( () => { this.showParcels(); }, 200);
      return;
    }
    this.lastEvent = setTimeout(() => {
      const that = this;
      that.lastEventProcessing = true;
      let cl = [];
      if (this.userModel.role < 3) {
        cl = [
          {data: 'internal_number', name: 'p.internal_number'},
          {data: 'status', name: 'pst.displayed_name'},
          {data: 'recipient_name', name: 'p.recipient_name'},
          {data: 'name', name: 'c.name'},
          {data: 'destination_city', name: 'p.destination_city'},
          {data: 'out_number', name: 'p.out_number'},
          {data: 'return_number', name: 'p.return_number'},
          {data: 'declared_content', name: 'p.declared_content'},
          {data: 'cash_on_delivery_value', name: 'p.cash_on_delivery_value'},
          {data: 'created_at', name: 'p.created_at'},
          {data: 'created_at', name: 'p.created_at'}
        ];
      } else {
        cl = [
          {data: 'internal_number', name: 'p.internal_number'},
          {data: 'status', name: 'pst.displayed_name'},
          {data: 'recipient_name', name: 'p.recipient_name'},
          {data: 'destination_city', name: 'p.destination_city'},
          {data: 'out_number', name: 'p.out_number'},
          {data: 'declared_content', name: 'p.declared_content'},
          {data: 'cash_on_delivery_value', name: 'p.cash_on_delivery_value'},
          {data: 'created_at', name: 'p.created_at'},
        ];
      }
      if (!this.dtInit) {
        setTimeout(
          () => {
            this.dtTrigger.next();
          }, 0
        );
      } else {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          try {
            dtInstance.destroy();
            this.dtTrigger.next();
            this.dtInit = false;
          } catch (e) {
          }
        });
      }

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 25,
        lengthMenu: [25, 500, 1000, 5000],
        serverSide: true,
        processing: true,
        stateSave: true,
        searchDelay: 250,
        order: [[cl.length - 2, 'DESC']],
        dom: '<"top"il>frt<"bottom"lp><"clear">',
        ajax: (dataTablesParameters: any, callback) => {
          that.http
            .post(
              this.apiUrl + 'app/v1/parcel/list',
              {...dataTablesParameters, criteria: this.criteria}
            ).subscribe((resp: any) => {
            this.timeout = 900;
            that.items = resp.data;
            that.dtInit = true;
            this.lastEvent = null;
            that.lastEventProcessing = false;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        },
        columns: cl,
        language: this.dtTranslation
      };
    }, this.timeout);
  }

  setGoToLocker(value) {
    this.goToLocker = value;
  }


  country(parcel): string {
    if (null !== parcel.buffer_number) {
      return '';
    }

    if (parcel.return_parcel) {
      if (4 === parcel.id_in_courier) {
        return 'Węgry';
      }
      else if (5 === parcel.id_in_courier) {
        return 'Bułgaria';
      }

      return 'Rumunia';
    }

    if (4 === parcel.id_out_courier) {
      return 'Węgry';
    }
    else if (5 === parcel.id_out_courier) {
      return 'Bułgaria';
    }

    return 'Rumunia';
  }

  flagUrl(parcel): string {
    if (null !== parcel.buffer_number) {
      return this.apiUrl + 'app/v1/asset/icon/danger';
    }

    if (parcel.return_parcel) {
      if (4 === parcel.id_in_courier) {
        return this.apiUrl + 'app/v1/asset/flag/HU';
      }
      else if (5 === parcel.id_in_courier) {
        return this.apiUrl + 'app/v1/asset/flag/BG';
      }

      return this.apiUrl + 'app/v1/asset/flag/RO';
    }

    if (4 === parcel.id_out_courier) {
      return this.apiUrl + 'app/v1/asset/flag/HU';
    }
    else if (5 === parcel.id_out_courier) {
      return this.apiUrl + 'app/v1/asset/flag/BG';
    }

    return this.apiUrl + 'app/v1/asset/flag/RO';
  }


  downloadFile(reAccount) {
    this.exportService.makeOrder({
      type: 'list',
      re_account: reAccount
    }).subscribe(
      (response: any) => {
        this.exportUuid = response.uuid;
        const subscription = this.exportService.showByUuid(response.uuid).subscribe(
          (subResponse: any) => {
            if ('validated' === subResponse.status) {
              this.toastsService.showMessage('Twoje zestawienie jest gotowe do pobrania!', 's');
              this.exportDownloadable = true;
            }
            if ('validation_error' === subResponse.status) {
              this.toastsService.showMessage('Nie możemy wygenerować twojego zestawienia!', 'w');
              this.exportUuid = null;
            }
          },
        );

        this.subscriptions.push(subscription);
        this.toastsService.showMessage('Generowanie zestawienia przesyłek zostało zlecone!', 's');
      },
      (error) => {
        if (error.status === 406) {
          this.toastsService.showMessage('Wygenerowanie pliku wedle zadanych kryteriów (processable) nie jest możliwy!', 'e');
        }
      }
    );
  }

  openGroupedStatus() {
    const modals =  document.getElementsByClassName('changeGrouppedStatus');
    if (modals.length > 1) {
      modals[1].remove();
    }
    UIkit.modal('#changeGrouppedStatus').show();
  }

  selectAll() {
    this.lastChecked = !this.lastChecked;
    for (const parcel of this.items) {
      parcel.selected = this.lastChecked;
    }
  }

  setGroupStatuses() {
    this.statusModel.ids = [];
    for (const parcel of this.items) {
      if (parcel.selected == 1) {
        this.statusModel.ids.push(parcel.id);
      }
    }

    this.parcelsService.setGroupStatus(this.statusModel).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Statusy przesyłek zostały zaaktualizowane pomyślnie!', 's');
        this.showParcels();
      }, (error: any)=>{
        if (error.status==420){
          this.toastsService.showMessage('Statusy przesyłek zostały zaaktualizowane, za wyjątkiem przesyłek o numerach '+error.error+', dla których status ten został nadany wcześniej.', 'w', 5000);
          this.showParcels();
        }
      }
    );
    UIkit.modal('#changeGrouppedStatus').hide();
  }

  protected readonly UserRole = UserRole;
}
